.Test {
  color: #c7ecf3;
}

.datagrid-container {
  height: calc(100vh - 130px);
  /* 占满屏幕高度 */
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.MuiDataGrid-paginationContainer {
  margin-top: auto;
  /* 强制分页控件固定在底部 */
}

.bankcard_center_text {
  white-space: nowrap;
}